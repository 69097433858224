<template>
  <div class="aboutdetails">
    <div class="aboutdetails-list">
      <div class="abur-list">
        <div class="abur-dianshang">{{detailist.title}}</div>
        <div class="abouttai-time">
            <img style="width: 12px;height: 12px;margin-right: 8px;" src="../assets/image/huisetime.png"/>
            <div class="aber-size">{{detailist.send_time}}</div>
        </div>
      </div>
      <!-- 视屏发布 -->
      <div>
        <!-- <video class="view-list" controls >
            <source :src="detailist.video" type="video/mp4">
        </video> -->
        <video class="view-list" :src="detailist.video" controls="controls" type="video/mp4"/>
        <!-- <div class="pinglun-list">
            <div class="fabu-pinglun">发布评论<span>文明上网理性发言，请遵守评论服务协议</span></div>
            <div class="fabu-pinglun-text">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                    maxlength="100"
                    show-word-limit
                    >
                </el-input>
               
            </div>
            <div class="fabuel-button"><el-button type="primary" @click="fabu()">发布</el-button></div>
        </div> -->
      </div>
      <!-- 全部评论 -->
      <!-- <div class="quanbu-pingliun">
        <div class="fabu-pinglun">全部评论<span>{{detailist.comment_number}}</span></div>
        <div class="pinglun-list-list" v-for="(item,index) in detailist.comments" :key="index">
            <div class="list-neijofn">
                <div class="list-neijofn-name">{{item.user.username}}</div>
                <div class="list-neijofn-center">{{item.details}}</div>
                <div class="list-neijofn-time">{{item.format_createtime}}</div>
            </div>
          

            <div class="huifu-list" v-for="(itema,indexa) in item.child" :key="indexa">
                <div class="huifu-list-name">平台回复：</div>
                <div class="huifu-list-center">{{itema.details}}</div>
                <div class="huifu-list-time">{{itema.format_createtime}}</div>
            </div>
        </div>
      </div> -->
      <!-- 推荐课程 -->
      <div class="kexhnrgjek-list" v-if="keclist.length>0">
        <div class="tuijian-kecheng">推荐课程</div>
        <div class="tuijian-kecheng-xuxilist">
            <div class="tuijian-xuxilist-anlisr" :class="(index + 1) % 3  == 0 ? 'meleanli':''" v-for="(item,index) in keclist" :key="index">
                <div class="tuijian-xuanlist-anli-img" @click="aboutdetls()"><img  :src="item.img"/></div>
                <div class="tuijian-xuanlist-biaoti">{{item.title}}</div>
                <div class="kanshu-anniu-lost">
                <div class="tuijian-renshu-zong">
                    <div><img style=" width: 14px;height: 14px;margin-right: 5px;" src="../assets/image/huiyan.png"/></div>
                    <div>{{item.browse_number}}</div>
                </div>
                <div class="kanshu-anniu-btn" @click="toxueexi(item)">立即学习</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name:"aboutdetails",
    data () {
      return {
        input:'',
        mocten:[{
          name:'美业'
        }],
        show:false,
        typelist:1,
        tiltundex:1,
        listQuery: {
        PageNo: 1,
        PageSize: 18,
      },
      total: 0,
      textarea:'',
      soulist:{
        id:''
      },
      detailid:'',
      detailist:[],
       xuanlist:{
          page:1,
          limit:3,
          industry_course_center_id:'0',
          course_course_center_id:'0',
          dev_course_center_id:'0',
          order_set:'0'
        },
        keclist:[]
      }
    },
    created(){
      this.detailid=this.$route.query.id
      console.log(this.detailid,'***%%%%')
      this.getsousuolist()
      this.keczxlist()
    },
    mounted () {
      
    },
    methods: {
        // 发布
      fabu(){
        let that = this
        that.$http({
          url: that.baseurl +"/doComment",
          method: "post",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{
            course_id:this.$route.query.id,
            details:that.textarea
          }
        })
          .then(res => {
              if(res.data.code==200){
                  that.$message.success(res.data.data);
                  that.textarea=""
                  this.getsousuolist()
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      // 立即学习
      toxueexi(data){
        this.$router.push('/aboutdetails?id='+data.id)
      },
       // 课程中心
      keczxlist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseIndex?page="+ that.xuanlist.page +'&limit='+that.xuanlist.limit+'&industry_course_center_id='+that.xuanlist.industry_course_center_id+'&course_course_center_id='+that.xuanlist.course_course_center_id+'&dev_course_center_id='+that.xuanlist.dev_course_center_id+'&order_set='+that.xuanlist.order_set,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.keclist = res.data.data.data.slice(0, 8);
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      //详情列表
      getsousuolist(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseInfo?id="+ that.detailid,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  that.detailist = res.data.data
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
    },
  }

</script>
<style scoped>
  .aboutdetails{
    width: 100%;
    background-color: #F2F5FB;
    padding-top: 87px;
  }
  .aboutdetails-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    margin: 0px auto;
    background-color: #ffffff;
    
    /* padding-bottom: 20px; */
    border: 1px solid #EFEFEF;
  }
  .abur-list{
    padding: 32px;
  }
  .abur-dianshang{
    font-size: 24px;
    color: #333333;
    font-weight: 550;
    padding-bottom: 20px;
  }
  .abouttai-time{
    display: flex;
    align-items: center;
  }
  .aber-size{
    font-size: 14px;
    color: #999999;
  }
  .view-list{
    width: 100%;
    height: 482px;
  }
  .fabu-pinglun{
    font-size: 18px;
    color: #333333;
    font-weight: 550;
    padding-bottom: 20px;
  }
  .fabu-pinglun span{
    font-size: 14px;
    color: #999999;
    margin-left: 15px;
    font-weight: 400;
  }
  .pinglun-list{
    padding: 32px;
  }
  .fabu-pinglun-text{
    height: 140px;
  }
  .fabuel-button{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .quanbu-pingliun{
    padding: 32px;
  }
  .pinglun-list-list{
    /* width: 94%;
    margin-left: 3%;
    margin-right: 3%; */
    border: 1px solid #DDDDDD;
    padding: 24px;
    margin-top: 24px;
  }
  .list-neijofn{
    padding: 20px 0;
    border-bottom: 1px solid #DDDDDD;
  }
  .list-neijofn-name{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
  }
  .list-neijofn-center{
    font-size: 14px;
    color: #333333;
    padding: 16px 0;
  }
  .list-neijofn-time{
    font-size: 14px;
    color: #999999;
  }
  .huifu-list{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 20px 0;
    border-bottom: 1px solid #DDDDDD;
  }
  .huifu-list-name{
    font-weight: 550;
    font-size: 16px;
    color: #0B2472;
  }
  .huifu-list-center{
    font-size: 14px;
    color: #333333;
    padding: 16px 0;
  }
  .huifu-list-time{
    font-size: 14px;
    color: #999999;
  }
  .kexhnrgjek-list{
    padding: 32px;
  }
  .tuijian-kecheng{
    font-weight: 550;
    font-size: 24px;
    color: #333333;
  }
  .tuijian-kecheng-xuxilist{
    width: 100%;

  }
   .tuijian-xuxilist-anlisr{
    width:calc(33.33% - 13.33px);
    display: inline-block;
    position: relative;
    height: 326px;
    background-color:#FFFFFF ;
    border-radius: 8px 8px 0 0;
    margin-top: 20px;
    margin-right: 20px;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
  }
  .tuijian-xuanlist-anli-img{
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-color:  #d3dce6;
  }
  .tuijian-xuanlist-anli-img img{
    width: 100%;
    height: 216px;
    border-radius: 8px 8px 0 0;
  }
  .tuijian-xuanlist-biaoti{
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    padding: 16px 16px;
  }
  .kanshu-anniu-lost{
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }
  .tuijian-renshu-zong{
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
  }
  .kanshu-anniu-btn{
    width: 96px;
    height: 32px;
    line-height: 32px;
    background-color: #275AF5;
    border-radius: 4px;
    color:#FFFFFF;
    font-size: 16px;
    text-align: center;
  }
   .meleanli{
    margin-right: 0;
  }
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
}
.fabu-pinglun-text .el-textarea__inner{
    height: 140px;
}
</style>
